.primaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D93CFF;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  /* height: 40px; */
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
}

.primaryButton:hover {
  opacity: 0.75;
}

.secondaryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: solid 2px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  height: 35px;
  color: white;
  box-sizing: border-box;
}

.secondaryButton:hover {
  border: solid 2px rgba(255, 255, 255, 1.0);
}
