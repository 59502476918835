@font-face {
  font-family: "Gilroy";
  src: url("../styles/fonts/Gilroy-ExtraBold.otf") format("opentype");
}

.landing {
  position: absolute;
  background-color: #373751;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.landingLHS {
  /* padding-left: 60px; */
  padding-left: 5%;
  margin-top: -80px;
}

.landingBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 125px;
}

@media (max-width: 1130px) {
  .landingLHS {
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 0 !important;
    align-items: center;
  }

  .landingBody {
    flex-direction: column;
  }

  .landingHeading {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingTitle {
    font-size: 42px !important;
  }

  .landingSubtitle {
    font-size: 18px !important;
  }

  .demoImage {
    padding-top: 60px !important;
    width: 50% !important;
  }
}

@media (max-width: 700px) {
  .landingLHS {
    width: 85%;
  }

  .landingHeading {
    width: 100% !important;
  }

  .landingTitle {
    font-size: 32px !important;
  }

  .landingSubtitle {
    font-size: 16px !important;
  }

  .ctaButtons {
    flex-direction: column !important;
  }

  .githubButton {
    margin-left: 0px !important;
    margin-top: 15px;
  }

  .demoImage {
    padding-top: 40px !important;
    width: 85% !important;
  }
}

.landingHeading {
  /* width: 650px; */
  width: 85%;
}

.landingTitle {
  color: white;
  font-size: 3.25vw;
  font-weight: 900;
  font-family: "Gilroy";
}

.landingSubtitle {
  color: rgba(255, 255, 255, 0.65);
  font-family: "Helvetica Neue";
  /* font-size: 22px; */
  font-size: 1.375vw;
  font-weight: 400;
  width: 85%;
}

.ctaButtons {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding-top: 15px;
  font-weight: 600;
  /* font-size: 1.125vw !important; */
}

.ctaButtons a {
  text-decoration: none;
}

.getStartedButton {
  color: white;
  height: 45px;
}

.githubButton {
  height: 45px !important;
  /* background-color: white; */
  margin-left: 15px;
}

.githubButton a {
  text-decoration: none;
  color: white;
}

.demoImage {
  /* width: 600px; */
  width: 62.5%;
  /* padding-right: 60px; */
  padding-right: 5%;
  max-width: 600px;
}
