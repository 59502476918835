.cm-s-dracula.CodeMirror, .cm-s-dracula .CodeMirror-gutters {
  background-color: #373751 !important; /* #140C58 !important; */
  color: #E5FCFF !important;
  border: none;
}
.cm-s-dracula .CodeMirror-gutters { color: #140C58; }
.cm-s-dracula .CodeMirror-cursor { border-left: solid thin #E5FCFF; }
.cm-s-dracula .CodeMirror-linenumber { color: #8985AB; }
.cm-s-dracula .CodeMirror-selected { background: rgba(255, 255, 255, 0.10); }
.cm-s-dracula .CodeMirror-line::selection, .cm-s-dracula .CodeMirror-line > span::selection, .cm-s-dracula .CodeMirror-line > span > span::selection { background: rgba(255, 255, 255, 0.10); }
.cm-s-dracula .CodeMirror-line::-moz-selection, .cm-s-dracula .CodeMirror-line > span::-moz-selection, .cm-s-dracula .CodeMirror-line > span > span::-moz-selection { background: rgba(255, 255, 255, 0.10); }
.cm-s-dracula span.cm-comment { color: #6272a4; } /* OLD */
.cm-s-dracula span.cm-string, .cm-s-dracula span.cm-string-2 { color: #f1fa8c; }
.cm-s-dracula span.cm-number { color: #bd93f9; }
.cm-s-dracula span.cm-variable { color: #FFF; }
.cm-s-dracula span.cm-variable-2 { color: #FFF; }
.cm-s-dracula span.cm-def { color: #279AF1; }
.cm-s-dracula span.cm-operator { color: #D93CFF; }
.cm-s-dracula span.cm-keyword { color: #D93CFF; }
.cm-s-dracula span.cm-atom { color: #bd93f9; }
.cm-s-dracula span.cm-meta { color: #f8f8f2; }
.cm-s-dracula span.cm-tag { color: #D93CFF; }
.cm-s-dracula span.cm-attribute { color: #50fa7b; }
.cm-s-dracula span.cm-qualifier { color: #50fa7b; }
.cm-s-dracula span.cm-property { color: #66d9ef; }
.cm-s-dracula span.cm-builtin { color: #45F0DF; }
.cm-s-dracula span.cm-variable-3, .cm-s-dracula span.cm-type { color: #FFF; }

.cm-s-dracula .CodeMirror-activeline-background { background: rgba(255,255,255,0.1); }
.cm-s-dracula .CodeMirror-matchingbracket { text-decoration: underline; color: white !important; }
