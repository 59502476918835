.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  border-bottom: 2px solid #54546A;  /* #4E4881 */
  background-color: #202030;
  border-top: 4px solid #D93CFF;
  padding-top: 2px;
  min-height: 55px;
  font-family: "Helvetica Neue";
  font-size: 16px;
  color: white;
}

.header .compactButtons {
  display: none;
  position: absolute;
}

@media (max-width: 700px) {
  .header .compactButtons {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .githubIcon img {
    height: 24px;
  }

  .githubIcon:hover {
    opacity: 0.75;
  }

  .keyIcon {
    margin-left: 15px;
    height: 16px;
    cursor: pointer;
  }

  .keyIcon:hover {
    opacity: 0.75;
  }

  .header .buttons {
    display: none !important;
    position: absolute !important;
  }
}

.header .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}

.header .buttons > a {
  text-decoration: none;
  color: white;
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: 400;
  padding-right: 25px;
  cursor: pointer;
}

.header .buttons .headerGithubButton a {
  padding-right: 0px !important;
  text-decoration: none;
  color: white;
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: 500;
}

.apiKeyButton {
  margin-left: 15px;
}

.header .buttons > a:hover {
  opacity: 0.75;
}

.logo img {
  height: 20px;
}
