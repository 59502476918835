.popupLayer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(32, 32, 48, 0.8);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #373751;  /* #140C58 */

  font-family: "Helvetica Neue";
  font-size: 16px;
  color: #FFFFFF;
}

.app .body {
  display: flex;
  flex-direction: row;
  height: calc(100% - 55px);
  overflow: auto;
}

.app .body .lhs {
  display: flex;
  flex-direction: column;
  width: 66%;
  height: 100%;
}

@media (max-width: 700px) {
  .app .body {
    flex-direction: column;
    width: 100%;
  }

  .app .body .lhs {
    width: 100%;
    height: 66%;
  }

  .errorExplanation {
    border-top: 2px solid #54546A;
    border-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 34% !important;
    width: 100%;
  }

  .errorExplanationHeader {
    padding-left: 30px;
  }

  .errorExplanationHeader p {
    padding-right: 30px;
  }
}
