@media (max-width: 1130px) {
  .popup {
    width: 75% !important;
  }
}

.popup {
  width: 50%;
  max-width: 600px;
  height: fit-content;
  background-color: #373751;
  box-shadow: 0px 2px 8px 2px rgba(32, 32, 48, 0.5);
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.popupInner {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 72%;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

.popupHeader {
  display: flex;
  flex-direction: column;
}

.popupHeading {
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 1.25px;
}

.popupSubheading {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  width: 75%;
  letter-spacing: 0.5px;
  padding-top: 15px;
}

.popupForm {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}

.inputField {
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
}

.inputText {
  border: 2px solid rgba(255, 255, 255, 0.65);
  box-sizing: border-box;
  outline: none;
  color: white;
  background-color: transparent;
  border-radius: 5px;
  resize: none;
  height: 45px;
  width: 100%;
  padding-top: 9px;
  font-size: 18px;
  font-family: "Helvetica Neue";
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 15px;
}

.inputText:placeholder {
  color: rgba(255, 255, 255, 0.35);
}

.popupSubmit {
  height: 45px;
}

.instructions {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding-top: 10px;
  color: rgba(255, 255, 255, 0.65);
}

.instructions a {
  color: #279AF1;
  font-weight: 600;
  text-decoration: none;
}

.instructions a:hover {
  text-decoration: underline;
}

.lastStep {
  padding-top: 3px;
}
