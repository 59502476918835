.codeEditorContainer {
  display: flex;
  flex-direction: column;
  height: 66%;
  overflow: auto;
  padding-left: 11px;
  padding-right: 24px;
  font-family: "Monaco" !important;
  font-size: 14px;
  border-bottom: 2px solid #54546A; /* #4E4881 */
}

.codeEditorHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 18px;
  padding-top: 20px;
  justify-content: space-between;
  height: 35px;
}

.languageDropdown__control {
  background-color: transparent !important;
  border-color: #9B9BA8 !important;
  border-radius: 5px !important;
  border-width: 2px !important;
  min-height: 35px !important;
  height: 35px !important;
}

.languageDropdown__single-value {
  color: white !important;
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: 500;
  margin-top: -5px !important;
}

.languageDropdown__indicators {
  margin-top: -2px !important;
}

.languageDropdown__indicator-separator {
  background-color: #9B9BA8 !important;
  width: 2px !important;
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.languageDropdown__dropdown-indicator {
  color: #9B9BA8 !important;
}

/* .languageDropdown {
  font-weight: 500 !important;
  font-family: "Helvetica Neue" !important;
  font-size: 16px !important;
  color: white !important;
  background: none !important;
} */

.lintButton {
  font-weight: 500;
  font-family: "Helvetica Neue" !important;
  font-size: 16px;
  background-color: #279AF1;
  width: 45px !important;
  margin-right: 6px;
}

.codeEditor {
  padding-top: 20px;
}

.CodeMirror {
  height: 100% !important;
}

.CodeMirror-lines {
  padding-left: 20px !important;
}

.CodeMirror-line {
  padding-left: 5px !important;
}

.CodeMirror-linenumber {
  margin-left: -20px;
}

.CodeMirror-code > div {
  padding-bottom: 2px; /* Line spacing */
}

.oldLine {
  background-color: rgba(39, 154, 241, 0.25);
}

.last {
  border-radius: 0px 0px 7px 7px;
}

.newLine {
  background-color: rgb(217, 60, 255, 0.25);
}

.first {
  border-radius: 7px 7px 0px 0px;
}

.both {
  border-radius: 7px;
}

.mergeLine {
  background-color: rgba(255, 255, 255, 0.15);
}

.useMeHeader {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  height: 40px;
  padding: 0px 5px 0px 5px;
  align-items: center;
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-weight: 900;
  /* letter-spacing: 0.75px; */
}

.oldCodeHeader {
  /* background-color: rgba(39, 154, 241, 0.33) !important; */
  background-color: #2F68A1 !important;
  border-radius: 7px 7px 0px 0px !important;
}

.newCodeHeader {
  /* background-color: rgb(217, 60, 255, 0.325); */
  background-color: #8839A8 !important;
  border-radius: 0px 0px 7px 7px;
  position: relative;
  top: 2px; /* Equal to line spacing */
}

.useMeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 65px;
  border-radius: 5px;
  cursor: pointer;
}

.useMeButton:hover {
  opacity: 0.75;
}

.newCodeButton {
  background-color: rgb(217, 60, 255, 1.0);
}

.oldCodeButton {
  background-color: #279AF1;
}

.useMeLabel {
  font-weight: 500 !important;
  padding-right: 5px;
  margin-left: auto;
}
